import React from 'react'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps'
import MapStyle from 'components/Map/MapStyle'

/* Used because google's drawn Circle is weirdly uneven */
const CIRCLE_ICON = {
  path: 'M 0, 0 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0',
  fillColor: '#56578D',
  fillOpacity: 0.3,
  strokeColor: '#56578D',
  strokeWidth: 5,
}

export default withScriptjs(
  withGoogleMap(props => {
    let center = { lat: props.location._lat, lng: props.location._long }
    return (
      <>
        <GoogleMap
          defaultZoom={14}
          defaultOptions={{
            styles: MapStyle,
            draggable: false,
            zoomControl: false,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
          }}
          zoomControl={false}
          center={props.location}
        >
          <Marker position={props.location} icon={CIRCLE_ICON} />
        </GoogleMap>
        <p className="map-explanation hint-text">
          Exact pickup location arranged after booking
        </p>
      </>
    )
  })
)
