import React, { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import Link from 'gatsby-link'
import { navigate } from 'gatsby'
import Moment from 'moment'

import './item.scss'
import ItemSingleMap from 'components/shared/Item/ItemSingleMap'
import ItemImages from 'components/shared/Item/ItemImages'
import DatePicker from 'components/shared/DatePicker/DatePicker'
import SearchURLPreloader from 'components/shared/SearchURLPreloader'
import SEO from 'components/SEO/SEO'
import ItemSearchDisplay from 'components/shared/Item/ItemSearchDisplay'
import CustomSlider from 'components/shared/Slider/CustomSlider'
import Layout from 'layouts/Page'
import { button } from 'utils/styles'
import ShareBtn from 'components/shared/ShareBtn'
import CustomAlert from 'components/shared/Forms/CustomAlert'
import { UserBlurb } from 'components/shared/Users/UserBlurb'
import { getRoute, deepClean } from 'utils'
import useAsyncEffect from 'utils/hooks/useAsyncEffect'
import DatabaseService from 'services/DatabaseService'
import { Match } from '@reach/router'
import DatabasePreloader from 'components/DatabasePreloader/DatabasePreloader'

const RentBtn = styled('button')`
  ${button.purple};
  ${button.big};
`

/**
 * Determines if a particular day on the calendar is blocked
 * @param {Moment.moment} moment
 * @param {Array} availability
 */
function isDayBlocked(moment, availability) {
  if (!availability || availability.length == 0) {
    return false
  }
  return availability.some(({ start_date, end_date }) => {
    // TODO: Determine granularity better
    if (moment.isBetween(start_date, end_date, null, '[]')) {
      return true
    }
    return false
  })
}

/**
 * Determines if the range of selected days has blocked days in it
 */
function isBlockedDateInRange(availability, startDate, endDate) {
  return availability.some(({ start_date, end_date }) => {
    return (
      Moment(start_date).isBetween(startDate, endDate) ||
      Moment(end_date).isBetween(startDate, endDate)
    )
  })
}

function BackButton(props) {
  const last_url =
    typeof window !== 'undefined' &&
    window.sessionStorage.getItem('mellow_last_url')
  return (
    <div className="row item-page-back-button">
      <Link to={last_url || '/search'}>&lt; Back to search</Link>
    </div>
  )
}

function RentContainer(props) {
  const { onSubmit, availability } = props

  const [[startDate, endDate], setDates] = useState([null, null])
  const [focusedInput, setFocusedInput] = useState(null)

  const [alert, setAlert] = useState({})
  const selectDates = useCallback(
    ({ startDate, endDate }) => {
      setAlert({})
      if (startDate && endDate) {
        if (isBlockedDateInRange(availability, startDate, endDate)) {
          return setAlert({
            type: 'error',
            message:
              'The item is already booked for one or more days in the range you selected.',
            visible: true,
          })
        }
      }
      setDates([startDate, endDate])
    },
    [availability]
  )

  const submitForm = useCallback(() => {
    if (!startDate || !endDate) {
      return setAlert({
        type: 'warning',
        message: 'Please select a start and end date.',
        visible: true,
      })
    }
    typeof onSubmit === 'function' && onSubmit(startDate, endDate)
  }, [startDate, endDate])

  return (
    <div className="product-rent-box">
      <h3 className="product-rent-header">When do you need it?</h3>
      <div className="datepicker-container">
        <div id="datepicker-flex">
          <div>
            <div className="DateRangePickerContainer">
              <div>
                <div className="DateRangePickerInput">
                  <DatePicker
                    startDate={startDate}
                    startDateId="mellow_start_date"
                    endDate={endDate}
                    endDateId="mellow_end_date"
                    onDatesChange={selectDates}
                    readOnly={true}
                    focusedInput={focusedInput}
                    onFocusChange={setFocusedInput}
                    isDayBlocked={moment => isDayBlocked(moment, availability)}
                  />
                </div>
              </div>
            </div>
          </div>

          <CustomAlert
            type={alert.type}
            visible={alert.visible}
            message={alert.message}
          />

          <div className="item-rent-request-date-form">
            <div>
              <div className="item-rent-request-buttons">
                <RentBtn
                  className="request-rent-btn"
                  id="request-rent-btn"
                  onClick={() => submitForm()}
                  role="link"
                  tabIndex="0"
                >
                  <div>Request to rent</div>
                </RentBtn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ItemImageDetails({
  item,
  onSubmit,
  availability,
  itemImageFeatured,
  toggleItemImagesBrowser,
  setCurrentItemImages,
}) {
  const { pictures = [] } = item
  return (
    <div className="item-image-details-container">
      <ItemImages
        pictures={pictures}
        itemImageFeatured={itemImageFeatured}
        onClick={toggleItemImagesBrowser}
        setCurrentItemImages={setCurrentItemImages}
      />
      <RentContainer onSubmit={onSubmit} availability={availability} />
    </div>
  )
}

function ItemLocationMap({ item } = {}) {
  const { _geoloc } = item
  return (
    <ItemSingleMap
      location={_geoloc}
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyA_0G3ZKX89wmcQdG84SbwYrh0bMwotH7U&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={
        <div
          className="product-map-container"
          style={{ height: `250px`, width: `100%`, borderRadius: `6px` }}
        />
      }
      mapElement={<div style={{ height: `100%` }} />}
    />
  )
}

function ProductSummary({ item = {} } = {}) {
  const { name, price_d, city, state } = item
  return (
    <div className="product-summary-container">
      <h1 className="product-title">{name}</h1>
      <div className="product-details-container">
        <div className="location-container">
          <h3 className="sub-header">{city}</h3>
          <p className="sub-text">{state}</p>
        </div>
        <div className="product-desktop">
          <h3 className="sub-header">
            <span>${price_d / 100}</span>
          </h3>
          <p className="sub-text"> a day</p>
        </div>
      </div>
    </div>
  )
}

function ProductDescription({ item = {} } = {}) {
  const { description, user, id, name } = item
  return (
    <div className="product-description-container">
      <div>
        <div className="item-details-description-container item-details-row">
          <h3 className="sub-title">What is it?</h3>
          <div className="item-description">
            <p className="desctiption-txt">{description}</p>
          </div>
        </div>
      </div>
      <div className="view-item-lender-container item-details-row">
        <h3 className="sub-title">Who owns it?</h3>
        <div className="lender-wrapper">
          <UserBlurb user={user} />
          <div className="share-wrapper">
            <ShareBtn
              name="item"
              text={'Rent ' + name + ' on Mellow'}
              link={getRoute('ITEM', { id })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function ProductFooter({ item = {}, ownerItems = [], moreProducts = [] } = {}) {
  const { user } = item
  return (
    <>
      {moreProducts.length ? (
        <div className="product-footer">
          <h3 className="sub-title">People also checked out</h3>
          <CustomSlider className="row" items={ownerItems}>
            {moreProducts.map(node => (
              <div className="slide" key={node.id}>
                <div className="slide-container">
                  <ItemSearchDisplay
                    key={node.id}
                    product={node}
                    showRent={false}
                    hideCity={true}
                  />
                </div>
              </div>
            ))}
          </CustomSlider>
        </div>
      ) : null}
      {ownerItems.length ? (
        <div className="more-items-footer">
          <h3 className="sub-title">{user.firstName} also owns</h3>
          <CustomSlider className="row" items={ownerItems}>
            {ownerItems.map(node => (
              <div className="slide" key={node.id}>
                <div className="slide-container">
                  <ItemSearchDisplay
                    key={node.id}
                    product={node}
                    showRent={false}
                    hideProfile={true}
                  />
                </div>
              </div>
            ))}
          </CustomSlider>
        </div>
      ) : null}
      <div className="how-it-works-footer">
        <div className="row">
          <h3 className="sub-title">How Renting works</h3>
          <div className="col-md-4 home-col">
            <h3>Make a Reservation</h3>
            <p>Pick the dates that work. Schedule for as long as you need.</p>
          </div>
          <div className="col-md-4 home-col">
            <h3>Wait for approval</h3>
            <p>
              The owner of the item will accept the request if it works with
              them.
            </p>
          </div>
          <div className="col-md-4 home-col">
            <h3>Meetup and Exchange</h3>
            <p>
              Coordinate a time to meet with the owner to exchange the item.
            </p>
          </div>
        </div>
        <div className="btn-row">
          <Link to="/how-it-works">
            <button className="learn-more-btn">Learn More</button>
          </Link>
        </div>
      </div>
    </>
  )
}

function ItemPage(props) {
  const { item } = props

  const { id, user } = item

  const [availability, setAvailability] = useState(null)
  // load availability
  useAsyncEffect(async () => {
    const availability = await DatabaseService.getItemAvailability(id)
    setAvailability(availability)
  }, [id])

  const [ownerItems, setOwnerItems] = useState([])
  // Loads the user's items
  // maybe do this when the user scrolls
  // to speed up performance
  useAsyncEffect(async () => {
    try {
      /** @type {firebase.firestore.QueryDocumentSnapshot} */
      const qSnap = await DatabaseService.getFleetByOwner(user.id)
      const data = qSnap.docs.map(docSnap => {
        return { id: docSnap.id, ...docSnap.data() }
      })
      setOwnerItems(data)
    } catch (error) {
      console.error(`Error getting documents: ${error}`)
    }
  }, [id])

  const onSubmit = useCallback(
    (startDate, endDate) => {
      // Object has to be cleaned before being passed via state
      // since having references causes circular structures which
      // also causes a silent failure
      const cleanItem = deepClean(item)

      const start_date = startDate._d
      const end_date = endDate._d

      const num_days = endDate.diff(startDate, 'days')
      const total_price_nofee = (cleanItem.price_d / 100) * num_days
      const service_fee = total_price_nofee * 0.15
      const total_price = total_price_nofee + service_fee

      const start_formatted = start_date.valueOf()
      const end_formatted = end_date.valueOf()

      const rental = {
        total_price_nofee,
        service_fee,
        total_price,
        start_date: start_formatted,
        end_date: end_formatted,
        num_days,
        item: cleanItem,
        item_name: cleanItem.name,
        price_d: cleanItem.price_d,
        item_img: cleanItem.img,
        lender: cleanItem.user,
        lender_name: cleanItem.user.firstName,
      }
      navigate('/rent-item', { state: { rental } })
    },
    [item]
  )

  return (
    <div className="product-page">
      <div className="product-rent-container">
        <BackButton />
        <ItemImageDetails
          // itemImageFeatured={}
          // toggleItemImagesBrowser={}
          // setCurrentItemImages={}
          item={item}
          onSubmit={onSubmit}
          availability={availability}
        />
        <ItemLocationMap item={item} />
        <ProductSummary item={item} />
        <ProductDescription item={item} />
        <ProductFooter item={item} ownerItems={ownerItems} />
      </div>
    </div>
  )
}

export default ({ location }) => {
  return (
    <Match path={'/item/:id/*'}>
      {({ match, location }) => (
        <DatabasePreloader
          match={match}
          map={`/fleet/:id`}
          populate={{
            fields: ['user'],
          }}
        >
          {item => (
            <Layout location={location}>
              <SEO
                title={`Rent ${item.name} for \$${item.price_d / 100} / day`}
                image={item.img}
                pathname={location.pathname}
              />
              <ItemPage item={item} />
            </Layout>
          )}
        </DatabasePreloader>
      )}
    </Match>
  )
}
