import React from 'react'
import Link from 'gatsby-link'
import Slider from 'react-slick'

import './CustomSlider.scss'

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className + ' side-arrow'}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        data-prefix="far"
        data-icon="chevron-right"
        className="svg-inline--fa fa-chevron-right slide-arrow"
        role="img"
        viewBox="0 0 256 512"
        data-ember-extension="1"
      >
        <path
          fill="currentColor"
          d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
        />
      </svg>
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className + ' side-arrow'}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        data-prefix="far"
        data-icon="chevron-left"
        className="svg-inline--fa fa-chevron-left slide-arrow"
        role="img"
        viewBox="0 0 256 512"
        data-ember-extension="1"
      >
        <path
          fill="currentColor"
          d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"
        />
      </svg>
    </div>
  )
}

export default class CustomSlider extends React.Component {
  render() {
    const { items = [], infinite = items.length > 4 } = this.props
    const settings = {
      dots: true,
      arrows: true,
      infinite: infinite,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 3,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: infinite,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    return <Slider {...settings}>{this.props.children}</Slider>
  }
}
