import React from 'react'
import {
  DayPickerRangeController,
  DateRangePicker,
  CalendarDay,
} from 'react-dates'

import './DatePicker.scss'
import { Mobile, Default } from 'utils/responsive'

// prevents undefined modifiers from crashing functions
const safeModifiers = modifiers => {
  return modifiers ? modifiers : new Set()
}

export default class DatePicker extends React.Component {
  render() {
    return (
      <>
        <Mobile>
          <DateRangePicker
            {...this.props}
            numberOfMonths={1}
            orientation="horizontal"
            hideKeyboardShortcutsPanel={true}
            renderCalendarDay={({ modifiers, ...props }) => {
              return (
                <CalendarDay modifiers={safeModifiers(modifiers)} {...props} />
              )
            }}
          />
        </Mobile>
        <Default>
          <DateRangePicker
            {...this.props}
            numberOfMonths={2}
            orientation="horizontal"
            renderCalendarDay={({ modifiers, ...props }) => {
              return (
                <CalendarDay modifiers={safeModifiers(modifiers)} {...props} />
              )
            }}
          />
        </Default>
      </>
    )
  }
}
