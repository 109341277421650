import React from 'react'
import ReactStars from 'react-stars'
import Link from 'gatsby-link'
import { navigate } from 'gatsby'

import './ItemSearchDisplay.scss'
import { getRoute } from 'utils'

export default class ItemSearchDisplay extends React.Component {
  render() {
    let {
      hideProfile,
      showEdit,
      hideCity,
      product,
      onMouseOver,
      onMouseOut,
    } = this.props
    let { id, name, price_d, user, city, state, pictures } = product

    return (
      <div
        className="product-container"
        onMouseOver={() => typeof onMouseOver === 'function' && onMouseOver(id)}
        onMouseOut={onMouseOut}
      >
        <div
          onClick={() => navigate(getRoute('ITEM', { id }))}
          className="product-item"
        >
          <div className="img-container">
            {pictures && <img src={pictures[0]} className="cover-img" />}
          </div>
          <div className="description">
            <div className="header">
              <h4 className="title">{name}</h4>
              <h5 className="price">
                <b>${price_d / 100}</b> /day
              </h5>
            </div>
            <div className="footer">
              <div className="owner">
                {!hideProfile && user && (
                  <>
                    <div
                      onClick={e => {
                        navigate(getRoute('PROFILE', { id: user.id }))
                        e.stopPropagation()
                      }}
                    >
                      {user.picture ? (
                        <img className="owner-img" src={user.picture} />
                      ) : (
                        <svg
                          className="owner-img"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          data-prefix="fas"
                          data-icon="user-circle"
                          role="img"
                          viewBox="0 0 496 512"
                          data-ember-extension="1"
                        >
                          <path
                            fill="currentColor"
                            d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
                          />
                        </svg>
                      )}
                    </div>
                    <div className="details-container">
                      <div
                        className="name_city"
                        onClick={e => {
                          navigate(getRoute('PROFILE', { id: user.id }))
                          e.stopPropagation()
                        }}
                      >
                        <div className="name">{user.firstName}</div>
                        {!hideCity ? (
                          <div className="city">{`(${city}, ${state})`}</div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="review-container">
                        <ReactStars
                          count={5}
                          value={user.avg_rating}
                          edit={false}
                          size={12}
                          color1={'#A9A9A9'}
                          color2={'#444'}
                        />
                        <div className="review-count">
                          ({user.rating_count})
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div>
                <button className="rent-btn">RENT</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
