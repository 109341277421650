import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { colors, radius } from 'utils/styles'

const ImageBox = styled('div')`
  display: flex;

  > .gatsby-image-outer-wrapper {
    flex: 5 100%;
    width: 100%;
  }

  .gatsby-image-wrapper {
    width: 100%;
  }
`

const PreviewWrapper = styled('div')`
  display: flex;
  margin-bottom: 5px;
`

const ImageLink = styled('a')`
  border: 2px solid transparent;
  border-radius: ${radius.default}px;
  box-sizing: border-box;
  display: block;

  height: 40px;
  width: 40px;
  margin-right: 10px;
  text-decoration: none;
  transition: 200ms all linear;
  img {
    height: 100% !important;
    margin: auto 0 !important;
    object-fit: cover !important;
    width: 100% !important;
    border-radius: ${radius.default}px;
  }

  &:focus,
  &:hover {
    background: ${colors.brand};
    border-color: ${colors.brand};
  }
`

const selectedImage = css`
  background: ${colors.brand};
  border-color: ${colors.brand};
`

export default class ItemImages extends React.Component {
  state = {
    currentImage: 0,
  }

  handleImageClick(index) {
    return event => {
      event.preventDefault()
      this.setState({ currentImage: index })
    }
  }

  render() {
    const { pictures, onClick } = this.props
    const currentImage = pictures[this.state.currentImage]
    if (!currentImage) {
      return null
    }

    return (
      <>
        <PreviewWrapper>
          {pictures.map((image, index) => {
            return (
              <ImageLink
                key={index}
                className={
                  index === this.state.currentImage ? selectedImage : ''
                }
                onClick={this.handleImageClick(index)}
                href={image}
              >
                <img src={image} />
              </ImageLink>
            )
          })}
        </PreviewWrapper>
        <ImageBox>
          <div className="item-carousel-wrapper">
            <img src={currentImage} className="product-rent-img" height="300" />
          </div>
        </ImageBox>
      </>
    )
  }
}
