import React from 'react'
import PropTypes from 'prop-types'
import qs from 'qs'
import { navigate } from 'gatsby'

import DatabaseService from 'services/DatabaseService'

export default class SearchURLPreloader extends React.Component {
  static propTypes = {
    // Accepts objects shaped like {[key:string]: string[]}
    // The array of strings represents fields to populate
    load: PropTypes.any.isRequired,
    onNotFound: PropTypes.func,
    location: PropTypes.any.isRequired,
  }

  static defaultProps = {
    // If this function returns true,
    //onNotFound: () => navigate('/'),
    onNotFound: () => console.log('not found'),
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // Store prevUserId in state so we can compare when props change.
    // Clear out any previously-loaded user data (so we don't render stale stuff).
    if (nextProps.location.search !== prevState.prevQuery) {
      return {
        prevQuery: nextProps.location.search,
        data: null,
      }
    }
    // No state update necessary
    return null
  }

  // Prevent infinite recursions when debugging (learned from experience)
  // No normal user will ever reach this number so it should be fine. Not
  // even a bot should be able to.
  DATA_RELOAD_LIMIT = 1000

  state = { data: null, prevQuery: '' }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate() {
    if (this.state.data === null) {
      this.loadData()
    }
  }

  // Add/modify collection keys in services/FirebaseServiceBase.js
  loadData = async () => {
    if (--this.DATA_RELOAD_LIMIT <= 0) {
      return
    }
    const { load = {}, location } = this.props
    const keys = Object.keys(load)
    if (keys.length === 0) {
      return
    }
    const params = qs.parse(location.search.replace(/^\?/, ''))
    try {
      const data = {}
      await Promise.all(
        keys.map(async collKey => {
          const res = await DatabaseService.getDocument({
            collection: collKey,
            id: params[collKey],
            populate: load[collKey],
          })
          /**
           * TODO: This doesn't check if populated fields exist, but let's not complicate
           *  things like that because this populate method shouldn't be used long term
           *  p.s. This message was typed on 1/4/19
           */
          if (!res.$exists && this.props.onNotFound(collKey, res) !== true) {
            throw new Error(
              `URL Param: ${collKey}=${params[collKey]} Could Not Be Found`
            )
          }
          data[collKey] = res
        })
      )
      this.setState({ data, loading: false })
    } catch (error) {
      console.error(error)
      // TODO: Redirect home on failure
    }
  }

  render() {
    if (!this.state.data) {
      return null
    }
    return this.props.children({ searchData: this.state.data, ...this.props })
  }
}
