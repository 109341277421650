import React from 'react'
import styled from '@emotion/styled'
import { colors, button, fonts, radius, spacing } from '../../../utils/styles'

import './share.scss'

const ShareButton = styled('a')`
  ${button.default};
  ${button.big};
  padding: 0
  :hover {
  }
`

export default class ShareBtn extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clicked: false,
    }
  }

  render() {
    let { link, text, name } = this.props
    console.log('this.props.link ', this.props.link)
    return (
      <div
        className="share-btn"
        onClick={() => this.setState({ clicked: true })}
      >
        {!this.state.clicked ? (
          <div className="share-txt">Share {name}</div>
        ) : (
          <div className="share-row">
            <a
              href={
                'https://twitter.com/share?url=https://borrowmellow.com' +
                this.props.link +
                '&amp;text=' +
                this.props.text
              }
              className="share-item"
              target="_blank"
            >
              <svg
                className="social-icon twitter"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                data-prefix="fab"
                data-icon="twitter"
                role="img"
                viewBox="0 0 512 512"
                data-ember-extension="1"
              >
                <path
                  fill="currentColor"
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                />
              </svg>
            </a>
            <a
              href={
                'http://www.facebook.com/sharer.php?u=https://borrowmellow.com' +
                this.props.link
              }
              className="share-item"
              target="_blank"
            >
              <svg
                className="social-icon f_book"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                data-prefix="fab"
                data-icon="facebook-f"
                role="img"
                viewBox="0 0 264 512"
                data-ember-extension="1"
              >
                <path
                  fill="currentColor"
                  d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"
                />
              </svg>
            </a>
            <a
              href={
                'http://reddit.com/submit?url=https://borrowmellow.com/search'
              }
              className="share-item"
              target="_blank"
            >
              {/*reddit removes query strings from links. Need to fix*/}
              <svg
                className="social-icon reddit"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                data-prefix="fab"
                data-icon="reddit-alien"
                role="img"
                viewBox="0 0 512 512"
                data-ember-extension="1"
              >
                <path
                  fill="currentColor"
                  d="M440.3 203.5c-15 0-28.2 6.2-37.9 15.9-35.7-24.7-83.8-40.6-137.1-42.3L293 52.3l88.2 19.8c0 21.6 17.6 39.2 39.2 39.2 22 0 39.7-18.1 39.7-39.7s-17.6-39.7-39.7-39.7c-15.4 0-28.7 9.3-35.3 22l-97.4-21.6c-4.9-1.3-9.7 2.2-11 7.1L246.3 177c-52.9 2.2-100.5 18.1-136.3 42.8-9.7-10.1-23.4-16.3-38.4-16.3-55.6 0-73.8 74.6-22.9 100.1-1.8 7.9-2.6 16.3-2.6 24.7 0 83.8 94.4 151.7 210.3 151.7 116.4 0 210.8-67.9 210.8-151.7 0-8.4-.9-17.2-3.1-25.1 49.9-25.6 31.5-99.7-23.8-99.7zM129.4 308.9c0-22 17.6-39.7 39.7-39.7 21.6 0 39.2 17.6 39.2 39.7 0 21.6-17.6 39.2-39.2 39.2-22 .1-39.7-17.6-39.7-39.2zm214.3 93.5c-36.4 36.4-139.1 36.4-175.5 0-4-3.5-4-9.7 0-13.7 3.5-3.5 9.7-3.5 13.2 0 27.8 28.5 120 29 149 0 3.5-3.5 9.7-3.5 13.2 0 4.1 4 4.1 10.2.1 13.7zm-.8-54.2c-21.6 0-39.2-17.6-39.2-39.2 0-22 17.6-39.7 39.2-39.7 22 0 39.7 17.6 39.7 39.7-.1 21.5-17.7 39.2-39.7 39.2z"
                />
              </svg>
            </a>
            <a
              href={
                'https://plus.google.com/share?url=https://borrowmellow.com' +
                this.props.link
              }
              className="share-item"
              target="_blank"
            >
              <svg
                className="social-icon google"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                data-prefix="fab"
                data-icon="google"
                role="img"
                viewBox="0 0 488 512"
                data-ember-extension="1"
              >
                <path
                  fill="currentColor"
                  d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                />
              </svg>
            </a>
          </div>
        )}
      </div>
    )
  }
}
